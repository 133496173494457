<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'SessionsLog',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SessionsLog',
      tableCaption: 'Журнал авторизации',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Журнал авторизации',
        },
      ],
      tableHeaders: [
        {
          text: 'Дата и время операции',
          order: 'moment',
          alias: 'moment',
        },
        {
          text: 'Статус',
          order: 'status',
          alias: 'status',
        },
        {
          text: 'Логин',
          order: 'login',
          alias: 'login',
        },
        {
          text: 'IP',
          order: 'ipAddress',
          alias: 'ipAddress',
        },
        {
          text: 'Пользователь',
          order: 'userName',
          alias: 'userName',
        },
      ],
    };
  },
};
</script>
